import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, } from '@angular/forms';
import { ProgPortalContextService } from 'src/app/core/services/progportalcontextservice';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { saveAs } from 'file-saver';
import { DialogReviewApproveComponent } from 'src/app/programs/dialog-review-approve/dialog-review-approve.component';
import { DialogReviewDenyComponent } from 'src/app/programs/dialog-review-deny/dialog-review-deny.component';
import { StatusDefinitionIds, StatusDefinitionShortDesc, StatusDefinitionUIShortDesc } from 'src/app/core/enums/statusDefinitionEnums';
import { CompanyCodeIds } from 'src/app/core/enums/companyCodeEnums';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { CoreRepository } from 'src/app/core/core.repository';
import { RouteParamsService } from 'src/app/core/services/route-params.service';
import { ProgramTableTypeIds } from 'src/app/core/enums/programTableTypeEnums';
import { EmailDefinitionIds } from 'src/app/core/enums/emailDefinitionEnums';
import { ActionMessages } from 'src/app/core/enums/emailEnums';
import { EmailOptions } from 'src/app/core/services/email/models/email-options.model';
import { EmailService } from 'src/app/core/services/email/email.service';
import { HttpHeaders } from '@angular/common/http';
import { EditProgramRouteforapprovalComponent } from '../edit-program-routeforapproval/edit-program-routeforapproval.component';
import { DialogPostMessageComponent } from 'src/app/programs/dialog-post-message/dialog-post-message.component';
import { date } from 'src/app/accruals/dialog-accruals-removeproducts/dialog-accruals-removeproducts.component';
import { ProgramCategories, ProgramCategorySetNames, ProgramCategoryGetNames } from 'src/app/core/enums/programCategoryEnums';
import { DOARoleDesc } from 'src/app/core/enums/doaRoleEnums';
import { Location } from '@angular/common'
import { MatAccordion } from '@angular/material/expansion';
import { DialogWarningMessageComponent, Messages } from 'src/app/dialogs/dialog-warning-message/dialog-warning-message.component';
import { AttachmentErrors } from 'src/app/core/enums/messageEnums';



export interface ProgramApproversEmail {
  programId: number;
  programType: number;
  emailDefinitionId: number;
  programApproverId: number;
  emailOptions?: any;
  changeExceptionId?: any;
  changeException: boolean;
}

export interface ProgramReviewersEmail {
  programId: number;
  programType: number;
  emailDefinitionId: number;
  programReviewerId: number;
  emailOptions?: any;
  changeExceptionId?: any;
  changeException: boolean;
}

export interface ProgramMessage {
  programId: number;
  message: string;
  programType: number;
  changeException: boolean;
  changeExceptionId?: number;
  emailOptions: any;
  statusDefinitionId: number;
}
export interface ApproverMessagesEmail {
  programId: number;
  emailOptions: any;
}


export interface ProgramsEmail {
  programId: number;
  programType: number;
  emailDefinitionId: number;
  programManagerId: number;
  emailOptions?: any;
  changeExceptionId?: any;
  changeException: boolean;
}

export interface DoaUsers {
  doaUserId: number;
  appUserId: number;
  userId: string;
  userName: string;
  lastName: string;
  firstName: string;
  email: string;
  approver: boolean;
}

export interface DoaApprovers {
  userName: string;
  doaRole: string;
  statusDefinitionId?: number;
  emailNotificationId?: number;
  approvalOrder : number;
  canceled: boolean,
  updateDate: Date,
  insertDate:Date,
  dateCompleted?: Date;
  daysWaiting?: number;
  emailNotificationInsertDate?: Date;
  adminStatusUpdate: boolean;
  approver: boolean;
  active: boolean;
}

export interface Message {
  updateBy: string;
  insertDate: Date;
  message: string;
  doaRole: string;
  statusDefinitionId: number;
}

export interface Status {
  statusDefinition: string;
  appUser: string;
  role: string;
  insertDate: Date;
}

@Component({
  selector: 'app-review-program',
  templateUrl: './review-program.component.html',
  styleUrls: ['./review-program.component.scss']
})
export class ReviewProgramComponent implements OnInit, AfterViewInit {
  @ViewChild('paginator', { read: MatPaginator }) paginator: MatPaginator;
  @ViewChild('sort', { read: MatSort }) sort: MatSort;
  @ViewChild('accordion') Accordion: MatAccordion;
  displayedColumns: string[] = ['productManager', 'email', 'status', 'daysOldSinceEmailNotification','lastAccrualChangeDate'];
  programForm: FormGroup;
  reviewForm: FormGroup;
  submitted = false;
  statusList: any;
  appUserList: any;
  programManagerList: any;
  channelList: any;
  yearList: any;
  businessSegmentList: any;
  programTypeList: any;
  companyCodeList: any;
  consistentNameList: any;
  cancelSubject = new Subject();
  comments: any;
  message: any;
  prodmgrs: any[];
  prodmgrids: any[];
  programId: number;
  strprodmgrids: any;
  reviewProgramsDto: ReviewProgramsDto;
  editProgram = false;
  deleteProgram = false;
  histories = [];
  approvals = [];
  businessSegSelected: string[];
  programCategory: string;
  programTableType: number;
  displayParent: boolean = false;
  displayChildren: boolean = false;
  programsAssociated: string = "";
  isStandardOrParentTypeProgram: boolean;
  approvalsCount: number;
  showApproval = false;
  showExpandButton = false;
  showRejection = false;
  showPostButton = false;
  showDaysWaiting: boolean = true;
  showApprovalsPendingChip: boolean = true;
  progCode: any;
  programManagerEmail: string;
  programManagerAppUserId: number;
  createdDate: Date;
  changeExceptionApprovedDate?: Date;
  changeExceptionCreatedDate?: Date;
  changeExceptionId?: any;
  parentProgramCode: any;
  parentProgramStatus: any;
  parentProgramShortName: any;
  statusDefinitionId: any;
  childProgramCodes: any;
  showParentProgram: boolean = true;
  programMessage: ProgramMessage;
  approverMessagesEmail : ApproverMessagesEmail
  routingApproversEmails: ProgramApproversEmail[] = [];
  routingReviewersEmails: ProgramReviewersEmail[] = [];
  routingProgramManagerEmails: ProgramsEmail[] = [];
  approversEmails: any[] = [];
  programCategories = ProgramCategories;
  ProgramCategorySetNames = ProgramCategorySetNames;
  ProgramCategoryGetNames = ProgramCategoryGetNames;
  programTableTypeIds = ProgramTableTypeIds;
  emailDefinitionIds = EmailDefinitionIds;
  actionMessages = ActionMessages;
  statusDefinitionIds = StatusDefinitionIds;
  statusDefinitionUIShortDescs = StatusDefinitionUIShortDesc;
  companyCodeIds = CompanyCodeIds;
  approversMessageEmailOptions = new EmailOptions();
  approversEmailOptions = new EmailOptions();
  reviewersEmailOptions = new EmailOptions();
  messageEmailOptions = new EmailOptions();
  programManagersEmailOptions = new EmailOptions();
  routingCanceledDate?: Date;
  doaUsers: DoaUsers[] = [];
  doaApprovers: DoaApprovers[] = [];
  messageList: Message[] = [];
  statusesList: Status[] = [];
  routingStartDate?: Date;
  emailOptions = new EmailOptions();
  emailNotifications: EmailNotification[] = [];
  emailProgramTypeText: string;
  dataSourceEmailNotifications: MatTableDataSource<EmailNotification>;
  fileDetailsList: any[] = [];
  parentProgramFileDetailsList: any[] = [];
  isBannerVisible: boolean = false;
  messagePosted:boolean = false;
  replicateProgram:boolean = false;
  isPriorDoaApproverBannerVisible: boolean = false;
  isChildProgramFinalApprover: boolean = false;
  statusDefinitionShortDesc = StatusDefinitionShortDesc;
  financialAssumptionsExists: boolean;
  companyCode: any;
  isCancelRerouteVisible = false;
  isChangeExceptionVisible = false;
  isEditDoaApproversVisible = false;
  isCEFinancialAssumptionsVisible = false;
  changeExceptionProgramsList: ChangeExceptionProgramDetails[] = [];
  isChangeException = false;
  masterProgramCode: any;
  existingInActiveChangeExceptionRouting: boolean;
  fileName: any;
  parentProgramFileName: any;
  doaMaxApprovalOrder: number;
  bundleRoute: boolean;
  private previousUrl: string = undefined;
  private currentUrl: string = undefined;
  warningMessages: Messages[] = [];
  attachmentErrors = AttachmentErrors;
  isProgramOwner: boolean;
  userName: string;
  childBundleRoutingProgramList: any;
  childProgramList: any;
  nextDoaApprover: string;
  canReplicateProgram: boolean = false;
  getMessagesUrl:string = '';
  getStatusesUrl:string = '';
  approveOrDenyUrl:string = '';
  adminUserName: string;
  isffProductAccrualSummaryV2: boolean = false;
  isffCheckAttachmentSize: boolean = false;

  totalSalesAndTotalExposureList: any[];
  constructor(private fb: FormBuilder,
    public context: ProgPortalContextService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private matSnackBar: MatSnackBar,
    private router: Router,
    public emailService: EmailService,
    public app: AppComponent,
    private route: ActivatedRoute,
    private core: CoreRepository,
    private routeParamsService: RouteParamsService,
    private location: Location) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      };
    });
  }

  ngOnInit(): void {
    this.spinner.show();
    this.checkFeatures();
    this.businessSegSelected = [];
    let parmId = this.route.snapshot.paramMap.get('id');
    this.progCode = parmId.toString().includes('-') ? parmId : parseInt(parmId);
    this.programForm = this.fb.group({
      ShortName: ['', Validators.required],
      ProgramCode: [''],
      ProgramManager: [this.core.getUserAccountName()],
      ConsistentName: [''],
      ProgramStatus: [''],
      ProgramDesc: [''],
      CompanyCode: [''],
      BusinessSegments: [this.businessSegSelected],
      TypeDefinition: [''],
      Channel: [''],
      BeginDate: [Date, Validators.required],
      EndDate: [Date, Validators.required],
      BundleRoute: [''],
      CreatedOn: [''],
      ApprovedOn: [''],
      ProgramYear: ['', Validators.required],
      CalculatedTotals: [''],
      TotalSales: [''],
      TotalExposure: [''],
      CurrentTotalSales: [''],
      CurrentTotalExposure: [''],
      TotalExposureDifference: [''],
      ExpensePa: [''],
      FileUpload: [''],
      Explanation: ['']
    });

    this.reviewForm = this.fb.group({
      ProgramId: [''],
      ProgramCode: [''],
      CompanyCode: [''],
      StatusDefinitionId: [''],
      Comments: [''],
      ProgramType: [''],
      ChangeException:[''],
      ChangeExceptionId:[''],
      NextDoaApprover:[''],
      DOARoleDesc: ['']
    });

    this.isStandardOrParentTypeProgram = false;
    this.dataSourceEmailNotifications = new MatTableDataSource([]);
    if (!this.progCode.toString().includes('-')) {
      this.prepopulateProgram(this.progCode);
    }
    else {
      this.changeExceptionPrepopulateProgram(this.progCode);
    }
    this.spinner.hide();
  }

  closeAllPanels() {
    this.Accordion.closeAll();
  }
  openAllPanels() {
    this.Accordion.openAll();
  }
  async prepopulateProgram(progCode) {
    await this.context.getdatafromService('/api/Program/Option/FunctionalArea').toPromise().then((response) => {
      if (response != null) {
        this.companyCodeList = response;
      }
    });

    await this.context.getdatafromService('/api/Program/Option/AppUser').toPromise().then((response) => {
      if (response != null) {
        this.appUserList = response;
      }
    });

    await this.context.getdatafromService('/api/Program/Option/BusinessSegment').toPromise().then((response) => {
      if (response != null) {
        this.businessSegmentList = response;
      }
    });
    await this.context.getdatafromService(`/api/Program/List/ParentChildList/${this.progCode}`).toPromise().then((response) => {
      if (response != null) {
        this.childBundleRoutingProgramList = response;
        this.childProgramList = response;
        this.childProgramList = this.childProgramList.filter(element => !element.isParent);
        this.childBundleRoutingProgramList = this.childBundleRoutingProgramList.filter(element => (element.isParent && !element.bundleRoute) || (!element.isParent && element.bundleRoute))
      }
    });

    this.spinner.show()
    this.context.getdatafromService(`/api/Program/Option/${this.progCode}`).toPromise().then((response) => {
      if (response != null) {
        this.spinner.hide();
        this.programForm.controls.ProgramCode.setValue(response.programCode);
        this.programForm.controls.ShortName.setValue(response.shortName);
        this.programForm.controls.ProgramManager.setValue(response.programManager);
        this.programForm.controls.ConsistentName.setValue(response.consistentName);
        this.programForm.controls.ProgramDesc.setValue(response.programDesc);
        this.companyCode = this.companyCodeList.filter(c => c.id == response.companyCode);
        this.programForm.controls.CompanyCode.setValue(this.companyCode[0].shortDesc);
        this.businessSegSelected = [];
        this.businessSegmentList.filter(c => response.businessSegments.includes(c.id)).forEach(
          b => this.businessSegSelected.push(b.shortDesc)
        );
        this.programForm.controls.BusinessSegments.setValue(this.businessSegSelected.join());
        this.programForm.controls.TypeDefinition.setValue(response.typeDefinition.shortDesc);
        this.programForm.controls.Channel.setValue(response.channel.shortDesc);
        this.programForm.controls.BeginDate.setValue(response.beginDate);
        this.programForm.controls.EndDate.setValue(response.endDate);
        this.programForm.controls.BundleRoute.setValue(response.BundleRoute);
        this.programForm.controls.ProgramYear.setValue(response.programYear.shortDesc);
        this.programForm.controls.TotalSales.setValue(response.totalSales);
        this.programForm.controls.TotalExposure.setValue(response.totalExposure);
        this.programForm.controls.ProgramStatus.setValue(response.bundleRoute ? this.statusDefinitionUIShortDescs._1 : response.statusDefinition.shortDesc);
        this.financialAssumptionsExists = response.totalExposure != null || response.totalSales != null;
        if (response.fileDetailsList) { this.fileDetailsList = response.fileDetailsList; }
        if (response.parentProgramFileDetailsList) { this.parentProgramFileDetailsList = response.parentProgramFileDetailsList; }
        this.parentProgramCode = response.parentProgramCode;
        this.parentProgramShortName = response.parentProgram !== null ? response.parentProgram.shortName : null;
        this.parentProgramStatus = this.statusDefinitionShortDesc['_' + response.parentProgramStatusDefinitionId];
        this.createdDate = response.createdDate;
        this.changeExceptionCreatedDate = response.changeExceptionCreatedDate;
        this.changeExceptionApprovedDate = response.changeExceptionApprovedDate;
        this.childProgramCodes = response.childProgramCodes;
        this.programId = response.id;
        this.programManagerEmail = response.appUser.email;
        this.programManagerAppUserId = response.appUser.id;
        this.bundleRoute = response.bundleRoute;
        this.isBannerVisible = response.canApprove;
        this.isPriorDoaApproverBannerVisible = response.priorDoaApprover;
        this.isChildProgramFinalApprover = response.childProgramFinalApproval;
        this.statusDefinitionId = response.statusDefinition.id;
        this.isChangeException = false;
        this.changeExceptionId = 0;
        this.showParentProgram = false;
        this.routingCanceledDate = response.routingCanceledDate;
        this.doaMaxApprovalOrder = response.doaApproversList.reduce((op, item) => op = op > item.approvalOrder ? op : item.approvalOrder, 0);
        this.doaApprovers = response.doaApproversList;
        this.nextDoaApprover = response.doaApproversList.filter(x => x.statusDefinitionId == null && x.approver)[0]?.userName;
        this.routingStartDate = response.routingStartDate;
        this.messageList = response.messageslist;
        this.statusesList = response.statusList;
        this.emailProgramTypeText = "program";
        if (this.messageList.length > 0) {
          this.showExpandButton = true;
        }
        this.doaApprovers.forEach((element) => {
          if (element.userName == this.core.getUserAccountName() && element.emailNotificationId != null) {
            this.showPostButton = true;
          }
          if (element.userName == this.core.getUserAccountName() && element.statusDefinitionId == this.statusDefinitionIds.APPROVED) {
            this.showPostButton = false;
          }
          if (element.statusDefinitionId == this.statusDefinitionIds.DENIED) {
            this.showApprovalsPendingChip = false;
          }
        });
        response.changeExceptionProgramDetails.forEach(cep => {
          if (cep.statusDefinitionId == this.statusDefinitionIds.APPROVED) {
            cep.approvedDate = new Date(cep.approvedDate).toLocaleDateString();
          }
          else {
            cep.approvedDate = '';
          }
        })
        this.changeExceptionProgramsList = response.changeExceptionProgramDetails && response.changeExceptionProgramDetails.length > 0 ? response.changeExceptionProgramDetails : [];
        if (response.statusDefinition.id == this.statusDefinitionIds.APPROVED || response.statusDefinition.id == this.statusDefinitionIds.DENIED) {
          this.showPostButton = false;
          if (response.statusDefinition.id == this.statusDefinitionIds.APPROVED) {
            this.showApproval = true;
            this.showRejection = false;
          }
          else if (response.statusDefinition.id == this.statusDefinitionIds.DENIED) {
            this.showRejection = true;
            this.showApproval = false;
          }
        }
        else if (response.currentApproverStatusDefinitionId != null) {
          if (response.currentApproverStatusDefinitionId == this.statusDefinitionIds.APPROVED) {
            this.showApproval = true;
            this.showRejection = false;
          }
          else if (response.currentApproverStatusDefinitionId == this.statusDefinitionIds.DENIED && response.statusDefinition.id == this.statusDefinitionIds.DENIED) {
            this.showRejection = true;
            this.showApproval = false;
          }
        }

        if (this.core.getUserAccountName() == this.programForm.controls.ProgramManager.value || this.isAdmin()) {
          this.isProgramOwner = true;
          switch (response.statusDefinition.id) {
            case this.statusDefinitionIds.DENIED:
              this.showPostButton = false;
              this.isCancelRerouteVisible = false;
              this.isChangeExceptionVisible = false;
              this.showRejection = true;
              this.showApproval = false;
              break;
            case this.statusDefinitionIds.APPROVAL_ROUTING:
              this.showPostButton = true;
              if (!this.bundleRoute) {
                this.isCancelRerouteVisible = true;
                this.isEditDoaApproversVisible = true;
              }
              this.isChangeExceptionVisible = false;
              break;
            case this.statusDefinitionIds.APPROVED:
              this.isEditDoaApproversVisible = true;
              this.showPostButton = false;
              this.showApproval = true;
              this.showRejection = false;
              this.isChangeExceptionVisible = true;
              if (response.changeExceptionProgramDetails.length > 0) {
                this.isChangeExceptionVisible = false;
              }
              break;
          }
        }

        switch (response.programTableType) {
          case this.programTableTypeIds.Program:
            this.programTableType = this.programTableTypeIds.Program;
            this.programCategory = this.ProgramCategorySetNames.StandardProgram;
            this.programForm.controls.CalculatedTotals.setValue(!response.calculatedTotals);
            this.programForm.controls.CalculatedTotals.disable();
            if (this.core.canEditProgram) {
              this.canReplicateProgram = true;
            }
            break;
          case this.programTableTypeIds.ExternalAccruingProgram:
            this.programTableType = this.programTableTypeIds.ExternalAccruingProgram;
            this.programCategory = this.ProgramCategorySetNames[response.externalAccruingProgramType];
            if (this.core.canEditProgram) {
              this.canReplicateProgram = true;
            }
            break;
          case this.programTableTypeIds.NonAccruingProgram:
            this.parentProgramCode = response.parentProgram.changeExceptionCode !== '' ? response.parentProgram.changeExceptionCode : response.parentProgram.code;
            this.programTableType = this.programTableTypeIds.NonAccruingProgram;
            this.programCategory = this.ProgramCategorySetNames.ChildProgram;
            this.displayParent = true;
            this.programsAssociated = this.ProgramCategorySetNames.ParentProgram;
            this.showParentProgram = true;
            break;
        }

        if (response.childProgramCodes && response.childProgramCodes.length > 0) {
          this.programCategory = this.ProgramCategorySetNames.ParentProgram;
          this.displayChildren = true;
          this.programsAssociated = "Children Programs List:";
        }
        if (this.core.getUserAccountName() != this.programForm.controls.ProgramManager.value && this.statusDefinitionId == this.statusDefinitionIds.DRAFT) {
          this.showPostButton = false;
        }
        if (this.programCategory === this.ProgramCategorySetNames.ParentProgram || (response.companyCode !== 'CANADA' && response.externalAccruingProgramType === '')) {
          this.isStandardOrParentTypeProgram = true;
        }
        if (response.totalSalesAndTotalExposure) { this.totalSalesAndTotalExposureList = response.totalSalesAndTotalExposure; }
        this.bindProductMangersAccrualsStatus();
      }
      this.spinner.hide();
    });
  }

  async changeExceptionPrepopulateProgram(progCode) {
    await this.context.getdatafromService('/api/Program/Option/FunctionalArea').toPromise().then((response) => {
      if (response != null) {
        this.companyCodeList = response;
      }
    });

    await this.context.getdatafromService('/api/Program/Option/AppUser').toPromise().then((response) => {
      if (response != null) {
        this.appUserList = response;
      }
    });

    await this.context.getdatafromService('/api/Program/Option/BusinessSegment').toPromise().then((response) => {
      if (response != null) {
        this.businessSegmentList = response;
      }
    });
    await this.context.getdatafromService(`/api/Program/ChangeException/Details/ParentChildList/${this.progCode.toString()}`).toPromise().then((response) => {
      if (response != null) {
        this.childProgramList = response;
      }
    });

    this.spinner.show()
    this.context.getdatafromService(`/api/Program/ChangeException/Details/${this.progCode}`).toPromise().then((response) => {
      if (response != null) {
        this.spinner.hide();
        this.programForm.controls.ProgramCode.setValue(response.code);
        this.programForm.controls.ShortName.setValue(response.shortName);
        this.programForm.controls.ProgramManager.setValue(response.programManager);
        this.programForm.controls.ConsistentName.setValue(response.consistentName);
        this.programForm.controls.ProgramDesc.setValue(response.desc);
        this.companyCode = this.companyCodeList.filter(c => c.id == response.companyCode);
        this.programForm.controls.CompanyCode.setValue(this.companyCode[0].shortDesc);
        this.businessSegSelected = [];
        this.businessSegmentList.filter(c => response.businessSegments.includes(c.id)).forEach(
          b => this.businessSegSelected.push(b.shortDesc)
        );
        this.programForm.controls.BusinessSegments.setValue(this.businessSegSelected.join());
        this.programForm.controls.TypeDefinition.setValue(response.typeDefinition.shortDesc);
        this.programForm.controls.Channel.setValue(response.channel.shortDesc);
        if (response.BundleRoute) {
          this.programForm.controls.ProgramStatus.setValue("See Parent Status");
        }
        else {
          this.programForm.controls.ProgramStatus.setValue(response.statusDefinition.shortDesc);
        }
        this.programForm.controls.Explanation.setValue(response.explanation);
        this.programForm.controls.CreatedOn.setValue(new Date(response.createdDate).toLocaleDateString());

        this.programForm.controls.ApprovedOn.setValue(response.changeExceptionApprovedDate != null ? new Date(response.changeExceptionApprovedDate).toLocaleDateString() : '');
        this.programForm.controls.BeginDate.setValue(response.beginDate);
        this.programForm.controls.EndDate.setValue(response.endDate);
        this.programForm.controls.ProgramYear.setValue(response.programYear.shortDesc);
        this.programForm.controls.TotalSales.setValue(response.currentTotalSales);
        this.programForm.controls.TotalExposure.setValue(response.currentTotalExposure);
        this.programForm.controls.TotalExposureDifference.setValue(response.diffExposure);

        this.financialAssumptionsExists = response.previousTotalExposure != null || response.currentTotalExposure != null || response.previousTotalSales != null || response.currentTotalSales != null;
        if (response.fileName) { this.fileDetailsList = response.fileName; }
        if (response.ParentProgramFileName) { this.parentProgramFileDetailsList = response.ParentProgramFileName; }
        this.parentProgramCode = response.parentProgramCode;
        this.parentProgramShortName = response.parentProgramShortName;
        this.parentProgramStatus = this.statusDefinitionShortDesc['_' + response.parentProgramStatusDefinitionId];;
        this.createdDate = response.createdDate;
        this.changeExceptionCreatedDate = response.changeExceptionCreatedDate;
        this.changeExceptionApprovedDate = response.changeExceptionApprovedDate;
        this.childProgramCodes = response.childProgramCodes;
        this.programId = response.masterProgramId;
        this.programManagerEmail = response.appUser.email;
        this.programManagerAppUserId = response.appUser.id;
        this.isBannerVisible = response.canApprove;
        this.isPriorDoaApproverBannerVisible = response.priorDoaApprover;
        this.isChildProgramFinalApprover = response.childProgramFinalApproval;
        this.statusDefinitionId = response.statusDefinition.id;
        this.masterProgramCode = response.code.split('-')[0];
        this.existingInActiveChangeExceptionRouting = response.existingInActiveChangeExceptionRouting;
        this.isChangeException = true;
        this.changeExceptionId = response.id;
        this.changeExceptionProgramsList = response.changeExceptionProgramDetails && response.changeExceptionProgramDetails.length > 0 ? response.changeExceptionProgramDetails : [];
        this.isCEFinancialAssumptionsVisible = true;
        this.showParentProgram = false;
        this.routingCanceledDate = response.routingCanceledDate;
        this.doaMaxApprovalOrder = response.doaApproversList.reduce((op, item) => op = op > item.approvalOrder ? op : item.approvalOrder, 0);
        this.doaApprovers = response.doaApproversList;
        this.nextDoaApprover = response.doaApproversList.filter(x => x.statusDefinitionId == null && x.approver)[0]?.userName;
        this.routingStartDate = response.routingStartDate;  
        this.messageList = response.messageslist;
        this.statusesList = response.statusList;
        this.childBundleRoutingProgramList = [];
        this.emailProgramTypeText = "change exception";

        response.changeExceptionProgramDetails.forEach(cep => {
          if (cep.statusDefinitionId == this.statusDefinitionIds.APPROVED) {
            cep.approvedDate = new Date(cep.approvedDate).toLocaleDateString();
          }
          else {
            cep.approvedDate = '';
          }
        })
        if (this.messageList.length > 0) {
          this.showExpandButton = true;
        }
        this.doaApprovers.forEach((element) => {
          if (element.userName == this.core.getUserAccountName() && element.emailNotificationId != null) {
            this.showPostButton = true;
          }
          if (element.userName == this.core.getUserAccountName() && element.statusDefinitionId == this.statusDefinitionIds.APPROVED) {
            this.showPostButton = false;
          }
          if (element.statusDefinitionId == this.statusDefinitionIds.DENIED) {
            this.showApprovalsPendingChip = false;
          }
        });
        if (response.statusDefinition.id == this.statusDefinitionIds.APPROVED || response.statusDefinition.id == this.statusDefinitionIds.DENIED) {
          this.showPostButton = false;
          if (response.statusDefinition.id == this.statusDefinitionIds.APPROVED) {
            this.showApproval = true;
            this.showRejection = false;
          }
          else if (response.statusDefinition.id == this.statusDefinitionIds.DENIED) {
            this.showRejection = true;
            this.showApproval = false;
          }
        }
        else if (response.currentApproverStatusDefinitionId != null) {
          if (response.currentApproverStatusDefinitionId == this.statusDefinitionIds.APPROVED) {
            this.showApproval = true;
            this.showRejection = false;
          }
          else if (response.currentApproverStatusDefinitionId == this.statusDefinitionIds.DENIED && response.statusDefinition.id == this.statusDefinitionIds.DENIED) {
            this.showRejection = true;
            this.showApproval = false;
          }
        }
        if (this.core.getUserAccountName() == this.programForm.controls.ProgramManager.value || this.isAdmin()) {
          this.isProgramOwner = true;
          this.isChangeException = true;
          this.isChangeExceptionVisible = this.changeExceptionProgramsList.some(w => parseInt(w.code.split('-')[1]) > parseInt(this.progCode.split('-')[1])) ? false : true;
          switch (response.statusDefinition.id) {
            case this.statusDefinitionIds.DENIED:
              this.showPostButton = false;
              this.isCancelRerouteVisible = false;
              this.isChangeExceptionVisible = false;
              this.showRejection = true;
              this.showApproval = false;
              break;
            case this.statusDefinitionIds.APPROVAL_ROUTING:
              this.showPostButton = true;
              if (!this.bundleRoute) {
                this.isCancelRerouteVisible = true;
                this.isEditDoaApproversVisible = true;
              }
              this.isChangeExceptionVisible = false;
              break;
            case this.statusDefinitionIds.APPROVED:
              this.showPostButton = false;
              this.showApproval = true;
              this.showRejection = false;
              break;
          }
        }
        switch (response.programTableType) {
          case this.programTableTypeIds.Program:
            this.programTableType = this.programTableTypeIds.Program;
            this.programCategory = this.ProgramCategorySetNames.StandardProgram;
            this.programForm.controls.CalculatedTotals.setValue(!response.calculatedTotals);
            this.programForm.controls.CalculatedTotals.disable();
            break;
          case this.programTableTypeIds.ExternalAccruingProgram:
            this.programTableType = this.programTableTypeIds.ExternalAccruingProgram;
            this.programCategory = this.ProgramCategorySetNames[response.externalAccruingProgramType];
            break;
          case this.programTableTypeIds.NonAccruingProgram:
            this.programTableType = this.programTableTypeIds.NonAccruingProgram;
            this.programCategory = this.ProgramCategorySetNames.ChildProgram;
            this.displayParent = true;
            this.programsAssociated = this.ProgramCategorySetNames.ParentProgram;
            this.showParentProgram = true;
            break;
        }

        if (response.childProgramCodes && response.childProgramCodes.length > 0) {
          if (!this.isChangeException) {
            this.programCategory = this.ProgramCategorySetNames.ParentProgram;
            this.displayChildren = true;
            this.programsAssociated = "Children Programs List:";
          }
        }
        if (this.core.getUserAccountName() != this.programForm.controls.ProgramManager.value && this.statusDefinitionId == this.statusDefinitionIds.DRAFT) {
          this.showPostButton = false;
        }
        if (this.programCategory === this.ProgramCategorySetNames.ParentProgram || (response.companyCode !== 'CANADA' && response.externalAccruingProgramType === '')) {
          this.isStandardOrParentTypeProgram = true;
        }
        if (response.totalSalesAndTotalExposure) { this.totalSalesAndTotalExposureList = response.totalSalesAndTotalExposure; }
        this.bindProductMangersAccrualsStatus();
      }
      this.spinner.hide();
    });
  }

  ngAfterViewInit() {
    if (this.dataSourceEmailNotifications !== undefined) {
      if (this.dataSourceEmailNotifications.data.length > 0) {
        this.dataSourceEmailNotifications.paginator = this.paginator;
        this.dataSourceEmailNotifications.sort = this.sort;
      }
    }
  }
  async checkFeatures() {
    await this.context
      .getdatafromService('/api/FeatureFlag')
      .toPromise()
      .then((response) => {
        const data = response;
        this.isffProductAccrualSummaryV2 = data.filter(x => x.featureFlagName === "428835_ChangeProductsAndAccrualSummaryReport")[0].enabled;      
        this.isffCheckAttachmentSize = data.filter(x => x.featureFlagName === "622954_CheckAttachmentSize")[0].enabled;
      });
  }


  get f() { return this.programForm.controls; }

  toFormData<T>(formValue: T) {
    const formData = new FormData();
    for (const key of Object.keys(formValue)) {
      const value = formValue[key];
      if (value && Array.isArray(value)) { // check for arrays
        for (const v of value) {
          formData.append(key, v);
        }
      } else {
        formData.append(key, value);
      }
    }
    return formData;
  }

  buildApproversMessageEmail(programCode)
  {
    this.approversEmails = [];
    if(this.programManagerEmail != this.core.getUsername()) {
      this.approversEmails.push(this.programManagerEmail);
    }
    var parentProgram = "";
    this.getUserName(this.core.getUserAccountName());
    var UserName = this.userName;
    var description = "<b>" + UserName + "</b> has approved <b>" + this.programForm.controls.ShortName.value + "</b> (<b>" + this.progCode + "</b>) and posted a comment."
                                                  
    var approvalComment = "The Approver's comment: " + this.reviewForm.controls.Comments.value;
    var nextSteps = "<li>Click on the link below to see the Comments Board.</li><li>Click on the Post button to reply as needed.</li>";
    var buttonLinkUrl = 'programs/review-program/' + programCode;;
    var buttonText = "Go to Review page";
    var emailSubject = "You have a new comment from Program Portal";
    var toAddress = this.approversEmails;
    var ccAddress = [];
    if (this.parentProgramCode) {
      parentProgram = this.parentProgramShortName + " (" + this.parentProgramCode + ")";
      this.approversMessageEmailOptions = this.emailService.buildActionEmailTemplate(this.actionMessages._4, description, nextSteps, buttonLinkUrl, buttonText, emailSubject, toAddress, ccAddress, approvalComment, null, parentProgram)
    }
    else {
      this.approversMessageEmailOptions = this.emailService.buildActionEmailTemplate(this.actionMessages._4, description, nextSteps, buttonLinkUrl, buttonText, emailSubject, toAddress, ccAddress, approvalComment)
    }
  }

  buildApproversEmail(approverEmail, programCode) {
    this.approversEmails = [];
    var parentProgram = "";

    this.approversEmails.push(approverEmail);
    var description = "A program manager has released a " + this.emailProgramTypeText + " <b>" + this.programForm.controls.ShortName.value + "</b> (<b>" + programCode + "</b>) so you can Approve, or post questions to the Program Manager.";
    var nextSteps = "<li>Click on the link below to review the " + this.emailProgramTypeText + ".</li><li>Post comments to the Program Manager for discussion.</li><li>Click Approve button to continue the approval process.</li>";
    var buttonLinkUrl = 'programs/review-program/' + programCode;;
    var buttonText = "Go to Review page";
    var emailSubject = "New " + this.emailProgramTypeText + " needs your Approval!";
    var toAddress = this.approversEmails;
    var ccAddress;
    var appUser = this.appUserList.filter(au => au.userName === this.nextDoaApprover && au.active);
    ccAddress = appUser.length > 0 ? [appUser[0].email] : [this.core.getUsername()];
    if (appUser.length > 0) {
      if (this.core.isAdmin && (appUser[0].email != this.core.getUsername())) {
        ccAddress.push(this.core.getUsername());
        this.getUserName(this.core.getUserAccountName())
        description = "<b>" + this.userName + " (ADMIN)</b> has released a " + this.emailProgramTypeText + " <b>" + this.programForm.controls.ShortName.value + "</b> (<b>" + programCode + "</b>) so you can Approve, or post questions to the Program Manager.";
      }
    }   

    if (this.parentProgramCode) {
      parentProgram = this.parentProgramShortName + " (" + this.parentProgramCode + ")";
      this.approversEmailOptions = this.emailService.buildActionEmailTemplate(this.actionMessages._1, description, nextSteps, buttonLinkUrl, buttonText, emailSubject, toAddress, ccAddress, null, null, parentProgram);
    }
    else {
      this.approversEmailOptions = this.emailService.buildActionEmailTemplate(this.actionMessages._1, description, nextSteps, buttonLinkUrl, buttonText, emailSubject, toAddress, ccAddress);
    }
  }

  buildBundleRoutedApproversEmail(approverEmail, programCode) {
    this.approversEmails = [];
    this.approversEmails.push(approverEmail);
    var bundledProgramList = "";
    if (this.childBundleRoutingProgramList.length > 0) {
      this.childBundleRoutingProgramList.forEach(element => {
        if (!element.isParent && element.bundleRoute) {
          bundledProgramList += "<li>" + element.shortName + " (" + element.code + ")</li>";
        }
      });
    }
    var description = "A program manager has released a " + this.emailProgramTypeText + " <b>" + this.programForm.controls.ShortName.value + "</b> (<b>" + programCode + "</b>) so you can Approve, or post questions to the Program Manager.";
    var nextSteps = "<li>Click on the link below to review the " + this.emailProgramTypeText + ".</li><li>Post comments to the Program Manager for discussion.</li><li>Click Approve button to continue the approval process.</li>";
    var buttonLinkUrl = 'programs/review-program/' + programCode;;
    var buttonText = "Go to Review page";
    var emailSubject = "New " + this.emailProgramTypeText + " needs your Approval!";
    var toAddress = this.approversEmails;
    var ccAddress;
    var appUser = this.appUserList.filter(au => au.userName === this.nextDoaApprover && au.active);
    ccAddress = appUser.length > 0 ? [appUser[0].email] : [this.core.getUsername()];
    if (appUser.length > 0) {
      if (this.core.isAdmin && (appUser[0].email != this.core.getUsername())) {
        ccAddress.push(this.core.getUsername());
        this.getUserName(this.core.getUserAccountName())
        description = "<b>" + this.userName + " (ADMIN)</b> has released a " + this.emailProgramTypeText + " <b>" + this.programForm.controls.ShortName.value + "</b> (<b>" + programCode + "</b>) so you can Approve, or post questions to the Program Manager.";
      }
    }

    this.approversEmailOptions = this.emailService.buildActionEmailTemplate(this.actionMessages._1, description, nextSteps, buttonLinkUrl, buttonText, emailSubject, toAddress, ccAddress, null, bundledProgramList);
  }


  buildFinalApprovedEmail(emails, shortName, programCode, isReviewer) {
    var parentProgram = "";
    var description = "Review the Approved " + this.emailProgramTypeText + " <b>" + shortName + "</b> (<b>" + programCode + "</b>) for any comments you would like to make.";
    var nextSteps = "<li>Click on the link below to review the " + this.emailProgramTypeText + ".</li>";
    var buttonLinkUrl = 'programs/review-program/' + programCode;;
    var buttonText = "Go to Review page";
    var emailSubject = "New " + this.emailProgramTypeText + " Approvals are complete!";
    var toAddress = emails;
    var ccAddress;
    var appUser = this.appUserList.filter(au => au.userName === this.nextDoaApprover && au.active);
    ccAddress = appUser.length > 0 ? [appUser[0].email] : [this.core.getUsername()];
    if (appUser.length > 0) {
      if (this.core.isAdmin && (appUser[0].email != this.core.getUsername())) {
        ccAddress.push(this.core.getUsername());
      }
    }

    if (isReviewer) {
      if (this.parentProgramCode) {
        parentProgram = this.parentProgramShortName + " (" + this.parentProgramCode + ")";
        this.reviewersEmailOptions = this.emailService.buildActionEmailTemplate(this.emailProgramTypeText.toLowerCase() === "program" ? this.actionMessages._2 : this.actionMessages._3, description, nextSteps, buttonLinkUrl, buttonText, emailSubject, toAddress, ccAddress, null, null, parentProgram);
      }
      else {
        this.reviewersEmailOptions = this.emailService.buildActionEmailTemplate(this.emailProgramTypeText.toLowerCase() === "program" ? this.actionMessages._2 : this.actionMessages._3, description, nextSteps, buttonLinkUrl, buttonText, emailSubject, toAddress, ccAddress);
      }
    }
    else {
      if (this.parentProgramCode) {
        parentProgram = this.parentProgramShortName + " (" + this.parentProgramCode + ")";
        this.programManagersEmailOptions = this.emailService.buildActionEmailTemplate(this.emailProgramTypeText.toLowerCase() === "program" ? this.actionMessages._2 : this.actionMessages._3, description, nextSteps, buttonLinkUrl, buttonText, emailSubject, toAddress, ccAddress, null, null, parentProgram);
      }
      else {
        this.programManagersEmailOptions = this.emailService.buildActionEmailTemplate(this.emailProgramTypeText.toLowerCase() === "program" ? this.actionMessages._2 : this.actionMessages._3, description, nextSteps, buttonLinkUrl, buttonText, emailSubject, toAddress, ccAddress);
      }
    }
  }

  buildBundleRoutedFinalApprovedEmail(emails, programCode, isReviewer) {
    var bundledProgramList = "";
    if (this.childBundleRoutingProgramList.length > 0) {
      this.childBundleRoutingProgramList.forEach(element => {
        if (!element.isParent && element.bundleRoute) {
          bundledProgramList += "<li>" + element.shortName + " (" + element.code + ")</li>";
        }
      });
    }
    var description = "Review the Approved " + this.emailProgramTypeText + " <b>" + this.programForm.controls.ShortName.value + "</b> (<b>" + programCode + "</b>) for any comments you would like to make.";
    var nextSteps = "<li>Click on the link below to review the " + this.emailProgramTypeText + ".</li>";
    var buttonLinkUrl = 'programs/review-program/' + programCode;;
    var buttonText = "Go to Review page";
    var emailSubject = "New " + this.emailProgramTypeText + " Approvals are complete!";
    var toAddress = emails;
    var ccAddress;
    var appUser = this.appUserList.filter(au => au.userName === this.nextDoaApprover && au.active);
    ccAddress = appUser.length > 0 ? [appUser[0].email] : [this.core.getUsername()];
    if (appUser.length > 0) {
      if (this.core.isAdmin && (appUser[0].email != this.core.getUsername())) {
        ccAddress.push(this.core.getUsername());
      }
    }

    if (isReviewer) {
      this.reviewersEmailOptions = this.emailService.buildActionEmailTemplate(this.emailProgramTypeText.toLowerCase() === "program" ? this.actionMessages._2 : this.actionMessages._3, description, nextSteps, buttonLinkUrl, buttonText, emailSubject, toAddress, ccAddress, null, bundledProgramList);
    }
    else {
      this.programManagersEmailOptions = this.emailService.buildActionEmailTemplate(this.emailProgramTypeText.toLowerCase() === "program" ? this.actionMessages._2 : this.actionMessages._3, description, nextSteps, buttonLinkUrl, buttonText, emailSubject, toAddress, ccAddress, null, bundledProgramList);
    }
  }

  buildDeniedEmail(emails, programCode, deniedDescription, deniedComment) {
    var parentProgram = "";
    var bundledProgramList = "";
    if (this.childBundleRoutingProgramList.length > 0) {
      this.childBundleRoutingProgramList.forEach(element => {
        if (!element.isParent && element.bundleRoute) {
          bundledProgramList += "<li>" + element.shortName + " (" + element.code + ")</li>";
        }
      });
    }
    var description = deniedDescription;
    var comment = deniedComment;
    var buttonLinkUrl = 'programs/review-program/' + programCode;
    var buttonText = "Go to Review page";
    var emailSubject = this.emailProgramTypeText + " Denied";
    var toAddress = emails;
    var ccAddress;
    var appUser = this.appUserList.filter(au => au.userName === this.nextDoaApprover && au.active);
    ccAddress = appUser.length > 0 ? [appUser[0].email] : [this.core.getUsername()];
    if (appUser.length > 0) {
      if (this.core.isAdmin && (appUser[0].email != this.core.getUsername())) {
        ccAddress.push(this.core.getUsername());
      }
    }

    if (this.parentProgramShortName && deniedComment.length > 0) {
      parentProgram = this.parentProgramShortName + " (" + this.parentProgramCode + ")";
      this.programManagersEmailOptions = this.emailService.buildDeniedEmailTemplate(description, comment, buttonLinkUrl, buttonText, emailSubject, toAddress, ccAddress, parentProgram);
    }
    else if (this.childProgramList.length > 0 && deniedComment.length == 0) {
      parentProgram = this.programForm.controls.ShortName.value + " (" + programCode + ")";
      this.programManagersEmailOptions = this.emailService.buildDeniedEmailTemplate(description, comment, buttonLinkUrl, buttonText, emailSubject, toAddress, ccAddress, parentProgram);
    }
    else if (this.childBundleRoutingProgramList.length > 1 && deniedComment.length > 0) {
      this.programManagersEmailOptions = this.emailService.buildDeniedEmailTemplate(description, comment, buttonLinkUrl, buttonText, emailSubject, toAddress, ccAddress, null, bundledProgramList);
    }
    else {
      this.programManagersEmailOptions = this.emailService.buildDeniedEmailTemplate(description, comment, buttonLinkUrl, buttonText, emailSubject, toAddress, ccAddress);
    }
  }

  buildMessageEmail(programCode) {
    this.approversEmails = [];
    if (this.programManagerEmail != this.core.getUsername()) {
      this.approversEmails.push(this.programManagerEmail);
    }
    var parentProgram = "";
    this.getUserName(this.core.getUserAccountName());
    var UserName = this.userName;
    var description = "There is a new comment from <b>" + UserName + "</b> on the Comments Board for <b>" + this.programForm.controls.ShortName.value + "</b> (<b>" + programCode + "</b>).";
    var nextSteps = "<li>Click on the link below to see the Comments Board.</li><li>Click on the Post button to reply as needed.</li>";
    var buttonLinkUrl = 'programs/review-program/' + programCode;;
    var buttonText = "Go to Review page";
    var emailSubject = "You have a new comment from Program Portal";
    var toAddress = this.approversEmails;
    var ccAddress = [];
    if (this.parentProgramCode) {
      parentProgram = this.parentProgramShortName + " (" + this.parentProgramCode + ")";
      this.messageEmailOptions = this.emailService.buildActionEmailTemplate(this.actionMessages._4, description, nextSteps, buttonLinkUrl, buttonText, emailSubject, toAddress, ccAddress, null, null, parentProgram);
    }
    else {
      this.messageEmailOptions = this.emailService.buildActionEmailTemplate(this.actionMessages._4, description, nextSteps, buttonLinkUrl, buttonText, emailSubject, toAddress, ccAddress);
    }
  }

  buildBundleRoutedMessageEmail(programCode) {
    this.approversEmails = [];
    if (this.programManagerEmail != this.core.getUsername()) {
      this.approversEmails.push(this.programManagerEmail);
    }
    var bundledProgramList = "";
    if (this.childBundleRoutingProgramList.length > 0) {
      this.childBundleRoutingProgramList.forEach(element => {
        if (!element.isParent && element.bundleRoute) {
          bundledProgramList += "<li>" + element.shortName + " (" + element.code + ")</li>";
        }
      });
    }
    this.getUserName(this.core.getUserAccountName());
    var UserName = this.userName;
    var description = "There is a new comment from <b>" + UserName + "</b> on the Comments Board for <b>" + this.programForm.controls.ShortName.value + "</b> (<b>" + programCode + "</b>).";
    var nextSteps = "<li>Click on the link below to see the Comments Board.</li><li>Click on the Post button to reply as needed.</li>";
    var buttonLinkUrl = 'programs/review-program/' + programCode;;
    var buttonText = "Go to Review page";
    var emailSubject = "You have a new comment from Program Portal";
    var toAddress = this.approversEmails;
    var ccAddress = [];
    this.messageEmailOptions = this.emailService.buildActionEmailTemplate(this.actionMessages._4, description, nextSteps, buttonLinkUrl, buttonText, emailSubject, toAddress, ccAddress, null, bundledProgramList);
  }

  createApproversEmailList(programId, programCode, programType, isApprover) {
    this.approversEmails = [];
    this.routingApproversEmails = [];
    const headers = new HttpHeaders().set('content-type', 'application/json');

    this.context.getdatafromService('/api/Program/Routing/Details/' + programId + '/' + programType + '/' + isApprover + '/' + false + '/' + this.isChangeException + '/' + this.progCode.toString()).toPromise().then((response) => {
      if (response != null) {
        this.doaUsers = response;
        let changeExceptionId = 0;
        changeExceptionId = this.changeExceptionId === 0 ? this.changeExceptionId = null : this.changeExceptionId;
        if (this.doaUsers.length > 0) {
          this.doaUsers.forEach(du => {
            if (this.childBundleRoutingProgramList.length > 0) {
              this.buildBundleRoutedApproversEmail(du.email, programCode);
            }
            else {
              this.buildApproversEmail(du.email, programCode);
            }
            this.routingApproversEmails.push({ programId: programId, programType: programType, emailDefinitionId: this.emailDefinitionIds.DOAAPPROVER_TO_DOAAPPROVER, programApproverId: du.appUserId, emailOptions: JSON.parse(JSON.stringify(this.approversEmailOptions)), changeExceptionId: changeExceptionId, changeException: this.isChangeException },);
          })
          if (this.routingApproversEmails.length > 0) {
            const headers = new HttpHeaders().set('content-type', 'application/json');

            this.context.postdatatoservice("/api/Email/Table/ApproverEmail", JSON.stringify(this.routingApproversEmails), headers)
              .toPromise().then((response) => {
                if (response != null) {
                  this.openSuccessSnackBar('Program Approved Successfully', 'x', 3000);
                  this.routingApproversEmails = [];
                }
              });
          }
        }
        else {
          this.openSuccessSnackBar('Program Approved Successfully', 'x', 3000);
        }
      }
    });
  }

  createFinalApprovedEmailList(programId, programCode, programType, isApprover, statusDefinitionId) {
    let reviewersEmails = [];
    let programManagersEmails = [];
    this.routingReviewersEmails = [];
    this.routingProgramManagerEmails = [];
    let changeExceptionId = 0;

    this.context.getdatafromService('/api/Program/Routing/Details/' + programId + '/' + programType + '/' + isApprover + '/' + false + '/' + this.isChangeException + '/' + this.progCode.toString()).toPromise().then((response) => {
      if (response != null) {
        this.doaUsers = response;
        if (this.doaUsers.length > 0) {
          this.doaUsers.forEach(du => {
            reviewersEmails.push(du.email);
          })
          changeExceptionId = this.changeExceptionId === 0 ? this.changeExceptionId = null : this.changeExceptionId;
          this.doaUsers.forEach(du => {
            if (this.childBundleRoutingProgramList.length > 0) {
              this.buildBundleRoutedFinalApprovedEmail(reviewersEmails, programCode, true);
            }
            else {
              this.buildFinalApprovedEmail(reviewersEmails, this.programForm.controls.ShortName.value, programCode, true);
            }
            this.routingReviewersEmails.push({ programId: programId, programType: programType, emailDefinitionId: this.emailDefinitionIds.DOAAPPROVER_TO_DOAREVIEWER, programReviewerId: du.appUserId, emailOptions: JSON.parse(JSON.stringify(this.reviewersEmailOptions)), changeExceptionId: changeExceptionId, changeException: this.isChangeException },);
          })
          programManagersEmails.push(this.programManagerEmail);

          if (this.childBundleRoutingProgramList.length > 0) {
            this.buildBundleRoutedFinalApprovedEmail(programManagersEmails, programCode, false);
            this.routingProgramManagerEmails.push({ programId: programId, programType: programType, emailDefinitionId: this.emailDefinitionIds.DOAAPPROVER_TO_PROGRAMMGR, programManagerId: this.programManagerAppUserId, emailOptions: JSON.parse(JSON.stringify(this.programManagersEmailOptions)), changeExceptionId: changeExceptionId, changeException: this.isChangeException },);
            this.childBundleRoutingProgramList.forEach(element => {
              if (!element.isParent && element.bundleRoute) {
                programManagersEmails = [];
                programManagersEmails.push(element.programManagerEmail);
                this.buildFinalApprovedEmail(programManagersEmails, element.shortName, element.code, false);
                this.routingProgramManagerEmails.push({ programId: element.programId, programType: this.programTableTypeIds.NonAccruingProgram, emailDefinitionId: this.emailDefinitionIds.DOAAPPROVER_TO_PROGRAMMGR, programManagerId: element.programManagerId, emailOptions: JSON.parse(JSON.stringify(this.programManagersEmailOptions)), changeExceptionId: changeExceptionId, changeException: this.isChangeException },);
              }
            });
          }
          else {
            this.buildFinalApprovedEmail(programManagersEmails, this.programForm.controls.ShortName.value, programCode, false);
            this.routingProgramManagerEmails.push({ programId: programId, programType: programType, emailDefinitionId: this.emailDefinitionIds.DOAAPPROVER_TO_PROGRAMMGR, programManagerId: this.programManagerAppUserId, emailOptions: JSON.parse(JSON.stringify(this.programManagersEmailOptions)), changeExceptionId: changeExceptionId, changeException: this.isChangeException },);
          }

          const headers = new HttpHeaders().set('content-type', 'application/json');
          if (this.routingProgramManagerEmails.length > 0) {
            this.context.postdatatoservice("/api/Email/Table/ProgramManagerEmail", JSON.stringify(this.routingProgramManagerEmails), headers)
              .toPromise().then((response) => {
                if (response != null) {
                  this.routingProgramManagerEmails = [];
                  if (this.routingReviewersEmails.length > 0) {
                    const headers = new HttpHeaders().set('content-type', 'application/json');

                    this.context.postdatatoservice("/api/Email/Table/ReviewerEmail", JSON.stringify(this.routingReviewersEmails), headers)
                      .toPromise().then((response) => {
                        if (response != null) {
                          this.openSuccessSnackBar('Program Approved Successfully', 'x', 3000);
                          this.routingReviewersEmails = [];
                        }
                      });
                  }
                  else {
                    this.openSuccessSnackBar('Program Approved Successfully', 'x', 3000);
                  }
                }

              });
          }
        }
        else {
          if (statusDefinitionId === this.statusDefinitionIds.APPROVED) {
            changeExceptionId = this.changeExceptionId === 0 ? this.changeExceptionId = null : this.changeExceptionId;
            programManagersEmails.push(this.programManagerEmail);
            if (this.childBundleRoutingProgramList.length > 0) {
              this.buildBundleRoutedFinalApprovedEmail(programManagersEmails, programCode, false);
              this.routingProgramManagerEmails.push({ programId: programId, programType: programType, emailDefinitionId: this.emailDefinitionIds.DOAAPPROVER_TO_PROGRAMMGR, programManagerId: this.programManagerAppUserId, emailOptions: JSON.parse(JSON.stringify(this.programManagersEmailOptions)), changeExceptionId: changeExceptionId, changeException: this.isChangeException },);
              this.childBundleRoutingProgramList.forEach(element => {
                if (!element.isParent) {
                  programManagersEmails = [];
                  programManagersEmails.push(element.programManagerEmail);
                  this.buildFinalApprovedEmail(programManagersEmails, element.shortName, element.code, false);
                  this.routingProgramManagerEmails.push({ programId: element.programId, programType: this.programTableTypeIds.NonAccruingProgram, emailDefinitionId: this.emailDefinitionIds.DOAAPPROVER_TO_PROGRAMMGR, programManagerId: element.ProgramManagerId, emailOptions: JSON.parse(JSON.stringify(this.programManagersEmailOptions)), changeExceptionId: changeExceptionId, changeException: this.isChangeException },);
                }
              });
            }

            const headers = new HttpHeaders().set('content-type', 'application/json');
            if (this.routingProgramManagerEmails.length > 0) {
              this.context.postdatatoservice("/api/Email/Table/ProgramManagerEmail", JSON.stringify(this.routingProgramManagerEmails), headers)
                .toPromise().then((response) => {
                  if (response != null) {
                    this.openSuccessSnackBar('Program Approved Successfully', 'x', 3000);
                    this.routingProgramManagerEmails = [];
                  }
                })
            }
          }
        }
      }
    });
  }
  createDeniedEmailNotification(programId, programCode, programManagerEmail, programType, deniedDescription, deniedComment) {
    let programManagersEmails = [];
    this.routingProgramManagerEmails = [];
    let changeExceptionId = this.changeExceptionId === 0 ? this.changeExceptionId = null : this.changeExceptionId;

    programManagersEmails.push(programManagerEmail);
    this.buildDeniedEmail(programManagersEmails, programCode, deniedDescription, deniedComment);
    this.routingProgramManagerEmails.push({ programId: programId, programType: programType, emailDefinitionId: this.emailDefinitionIds.DOAAPPROVER_TO_PROGRAMMGR, programManagerId: this.programManagerAppUserId, emailOptions: JSON.parse(JSON.stringify(this.programManagersEmailOptions)), changeExceptionId: changeExceptionId, changeException: this.isChangeException },);

    const headers = new HttpHeaders().set('content-type', 'application/json');
    if (this.routingProgramManagerEmails.length > 0) {
      this.context.postdatatoservice("/api/Email/Table/ProgramManagerEmail", JSON.stringify(this.routingProgramManagerEmails), headers)
        .toPromise().then((response) => {
          if (response != null) {
            this.routingProgramManagerEmails = [];
            this.openSuccessSnackBar('Program Denied Successfully', 'x', 3000);
          }
        });
    }
  }
  createApprovedProgramMessageEmailNotification(programId, programCode, programType) {
    this.routingProgramManagerEmails = [];
    let changeExceptionId = this.changeExceptionId === 0 ? this.changeExceptionId = null : this.changeExceptionId;

    this.buildApproversMessageEmail(programCode);
    this.routingProgramManagerEmails.push({ programId: programId, programType: programType, emailDefinitionId: this.emailDefinitionIds.DOAAPPROVER_TO_PROGRAMMGR, programManagerId: this.programManagerAppUserId, emailOptions: JSON.parse(JSON.stringify(this.approversMessageEmailOptions)), changeExceptionId: changeExceptionId, changeException: this.isChangeException });

    const headers = new HttpHeaders().set('content-type', 'application/json');
    if (this.routingProgramManagerEmails.length > 0 && this.statusDefinitionId != this.statusDefinitionIds.APPROVED) {
      this.context.postdatatoservice("/api/Email/Table/ProgramManagerEmail", JSON.stringify(this.routingProgramManagerEmails), headers)
        .toPromise().then((response) => {
          if (response != null) {
            this.routingProgramManagerEmails = [];
            this.openSuccessSnackBar('Program Approved Successfully', 'x', 3000);
          }
        });
    }
  }
  sendEmailNotificationToApprovers(programId, programCode, programType) {
    this.createApproversEmailList(programId, programCode, programType, true);
  }

  sendEmailNotificationToReviewers(programId, programCode, programType, statusDefinitionId) {
    this.createFinalApprovedEmailList(programId, programCode, programType, false, statusDefinitionId);
  }

  sendDenyEmailNotification(programId, programCode, programManagerEmail, programType, deniedDescription, deniedComment) {
    this.createDeniedEmailNotification(programId, programCode, programManagerEmail, programType, deniedDescription, deniedComment);
  }

  sendApprovedProgramMessageEmailNotificationToProgramManagers(programId, programCode, programType) {
    this.createApprovedProgramMessageEmailNotification(programId, programCode, programType);
  }

  onSubmitDuplicate() {
  }

  onSubmitDelete() {
  }

  openApproveDialog() {
    this.setApproveOrDenyCall();
    let changeExceptionId = 0;
    changeExceptionId = this.changeExceptionId == null ? 0 : this.changeExceptionId;
      const dialogRef = this.dialog.open(DialogReviewApproveComponent, {
        data: { comment: this.comments}
      });
    
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          let programType = this.programCategory != null ? this.programCategory.replace(/\s/g, "").replace(/[^a-zA-Z ]/g, "_") : this.programCategory;
          this.reviewForm.controls.ProgramId.setValue(this.programId);
          this.reviewForm.controls.ProgramCode.setValue(this.programForm.controls.ProgramCode.value);
          this.reviewForm.controls.CompanyCode.setValue(this.companyCode[0].id);
          this.reviewForm.controls.StatusDefinitionId.setValue(this.statusDefinitionIds.APPROVED);
          this.reviewForm.controls.Comments.setValue(result.comments);
          this.reviewForm.controls.ProgramType.setValue(this.ProgramCategoryGetNames[programType]);
          this.reviewForm.controls.ChangeException.setValue(this.isChangeException);
          this.reviewForm.controls.ChangeExceptionId.setValue(changeExceptionId);
          if(this.doaApprovers.find(da => da.userName == this.core.getUserAccountName())){
            this.reviewForm.controls.DOARoleDesc.setValue(this.doaApprovers.find(da => da.userName == this.core.getUserAccountName()).doaRole);
          }
          if(this.isAdmin()){
            this.reviewForm.controls.NextDoaApprover.setValue(this.nextDoaApprover);
          }
          if (this.messageList.length > 0) { this.showExpandButton = true; }
          this.context.postdatatoservice(this.approveOrDenyUrl, this.toFormData(this.reviewForm.value))
            .pipe(takeUntil(this.cancelSubject)).toPromise().then((response) => {
              if (response !== null) {
                this.statusDefinitionId = response.statusDefinition.id;
                switch (response.statusDefinition.id) {
                  case this.statusDefinitionIds.APPROVAL_ROUTING:
                    this.canReplicateProgram = false;
                    this.sendEmailNotificationToApprovers(this.programId, this.progCode, this.programTableType);
                    this.programForm.controls.ProgramStatus.setValue(this.statusDefinitionShortDesc['_' + response.statusDefinition.id]);
                    response.itemDoaApproversList.forEach(da => {
                      if (da.userName == this.core.getUserAccountName() && da.statusDefinitionId == this.statusDefinitionIds.APPROVED) {
                        this.showPostButton = false;
                      }
                    })
                    break;
                  case this.statusDefinitionIds.APPROVED:
                    this.sendEmailNotificationToReviewers(this.programId, this.progCode, this.programTableType, response.statusDefinition.id)
                    this.programForm.controls.ProgramStatus.setValue(this.statusDefinitionShortDesc['_' + response.statusDefinition.id]);
                    this.showPostButton = false;
                    break;
                  default:
                    this.openSuccessSnackBar('Program Approved Successfully', 'x', 3000);
                    break;
                }
                if (result.comments) {
                    this.sendApprovedProgramMessageEmailNotificationToProgramManagers(this.programId, this.programForm.controls.ProgramCode.value, this.programTableType)
              }

                this.doaApprovers = response.itemDoaApproversList;
                this.context.getdatafromService(this.getMessagesUrl).toPromise().then((responseMsg) => {
                  if (responseMsg != null) {
                    this.messageList = responseMsg;
                    if (this.messageList.length > 0) {
                      this.showExpandButton = true;
                  }
                }
                });
                this.context.getdatafromService(this.getStatusesUrl).toPromise().then((responseStatus) => {
                  if (responseStatus != null) {
                    this.statusesList = responseStatus;
                  }
                });
              }
              this.showApproval = true;
            },
              msg => { // Error
                this.openErrorSnackBar('Error in Approving the Program', 'x', 3000);
              })
            .catch(error => this.openErrorSnackBar(error.message, 'x', 3000));
        }
      });
  }

  openDenyDialog() {
    this.setApproveOrDenyCall();
    let changeExceptionId = 0;
    changeExceptionId = this.changeExceptionId == null ? 0 : this.changeExceptionId;
      const dialogRef = this.dialog.open(DialogReviewDenyComponent, {
        data: { comment: this.comments }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result != undefined) {
          let programType = this.programCategory != null ? this.programCategory.replace(/\s/g, "").replace(/[^a-zA-Z ]/g, "_") : this.programCategory;
          this.reviewForm.controls.ProgramId.setValue(this.programId);
          this.reviewForm.controls.ProgramCode.setValue(this.programForm.controls.ProgramCode.value);
          this.reviewForm.controls.CompanyCode.setValue(this.companyCode[0].id);
          this.reviewForm.controls.StatusDefinitionId.setValue(this.statusDefinitionIds.DENIED);
          this.reviewForm.controls.Comments.setValue(result.comments);
          this.reviewForm.controls.ProgramType.setValue(this.ProgramCategoryGetNames[programType]);
          this.reviewForm.controls.ChangeException.setValue(this.isChangeException);
          this.reviewForm.controls.ChangeExceptionId.setValue(changeExceptionId);
          if(this.doaApprovers.find(da => da.userName == this.core.getUserAccountName())){
            this.reviewForm.controls.DOARoleDesc.setValue(this.doaApprovers.find(da => da.userName == this.core.getUserAccountName()).doaRole);
          }         
          this.showPostButton=false;
          this.showDaysWaiting=false;
          this.showApprovalsPendingChip=false;
          if(this.isAdmin()){
            this.reviewForm.controls.NextDoaApprover.setValue(this.nextDoaApprover);
          }
          this.context.postdatatoservice(this.approveOrDenyUrl, this.toFormData(this.reviewForm.value))
            .pipe(takeUntil(this.cancelSubject)).toPromise().then((response) => {
              if (response !== null) {
                if (response.statusDefinition.id === this.statusDefinitionIds.DENIED) {
                  this.canReplicateProgram = false;
                  let deniedDescription = "Your " + this.emailProgramTypeText + " <b>" + this.programForm.controls.ShortName.value + "</b> (<b>" + this.progCode + "</b>) has been denied by ";
                  let deniedComment = "The Approver's comment: " + this.reviewForm.controls.Comments.value;
                  this.sendDenyEmailNotification(this.programId, this.progCode, this.programManagerEmail, this.programTableType, deniedDescription, deniedComment);
                  //Begin:Send emails for child programs when a parent program is denied.
                  if (this.programTableType == this.programTableTypeIds.Program || this.programTableType == this.programTableTypeIds.ExternalAccruingProgram) {
                    if (this.childProgramList.length > 0) {
                      this.childProgramList.forEach(childProgram => {
                        this.sendDenyEmailNotification(childProgram.programId, childProgram.code, childProgram.programManagerEmail, this.programTableTypeIds.NonAccruingProgram, "Your " + this.emailProgramTypeText + " <b>" + childProgram.shortName + "</b> (<b>" + childProgram.code + "</b>) has been denied due to parent program Denial by ", "");
                      });
                    }
                  }
                  //End
                }
                this.doaApprovers = response.itemDoaApproversList;
                this.context.getdatafromService(this.getMessagesUrl).toPromise().then((responseMsg) => {
                  if (responseMsg != null) {
                    this.messageList = responseMsg;
                    if (this.messageList.length > 0) {
                      this.showExpandButton = true;
                  }
                }
                });
                this.context.getdatafromService(this.getStatusesUrl).toPromise().then((responseStatus) => {
                  if (responseStatus != null) {
                    this.statusesList = responseStatus;
                  }
                });
              }
              this.programForm.controls.ProgramStatus.setValue(this.statusDefinitionShortDesc['_' + response.statusDefinition.id]);
              this.statusDefinitionId = response.statusDefinition.id;
              this.showRejection = true;
            },
              msg => { // Error
                this.openErrorSnackBar('Error in Denying the Program', 'x', 3000);
              })
            .catch(error => this.openErrorSnackBar(error.message, 'x', 3000));
        }
      });
  }
  
  setApproveOrDenyCall() {
    if (!this.isChangeException) {
      this.approveOrDenyUrl = '/api/Program/Table/ApproveOrDeny';
    }
    else {
      this.approveOrDenyUrl = '/api/Program/ChangeException/Table/ApproveOrDeny';
    }
    if (!this.isChangeException) {
      this.getMessagesUrl = '/api/Program/Option/GetMessages/' + this.programId + '/' + this.programTableType;
      this.getStatusesUrl = '/api/Program/Option/GetStatuses/' + this.programId + '/' + this.programTableType;
    }
    else {
      this.getMessagesUrl = '/api/Program/ChangeException/Details/GetMessages/' + this.programId + '/' + this.programTableType + '/' + this.changeExceptionId;
      this.getStatusesUrl = '/api/Program/ChangeException/Details/GetStatuses/' + this.changeExceptionId + '/' + this.programTableType;
    }
  }

  ChangeException() {
    this.reviewForm.controls.ProgramCode.setValue(this.programForm.controls.ProgramCode.value);
    this.router.navigateByUrl('programs/edit-program/' + this.reviewForm.controls.ProgramCode.value);
  }



  cancelReroute() {
    let url = '';
    if (!this.isChangeException) {
      url = '/api/Program/Table/CancelReroute/';
    }
    else {
      url = '/api/Program/ChangeException/Table/CancelReroute/';
    }
    let changeExceptionId = 0;
    changeExceptionId = this.changeExceptionId == null ? 0 : this.changeExceptionId;
    let programType = this.programCategory != null ? this.programCategory.replace(/\s/g, "").replace(/[^a-zA-Z ]/g, "_") : this.programCategory;
    this.reviewForm.controls.ProgramId.setValue(this.programId);
    this.reviewForm.controls.ProgramCode.setValue(this.programForm.controls.ProgramCode.value);
    this.reviewForm.controls.CompanyCode.setValue(this.companyCode[0].id);

    switch (this.programTableType) {
      case this.programTableTypeIds.Program:
        this.reviewForm.controls.StatusDefinitionId.setValue(this.statusDefinitionIds.ACCRUALS_COMPLETED);
        break;
      case this.programTableTypeIds.ExternalAccruingProgram:
        this.reviewForm.controls.StatusDefinitionId.setValue(this.statusDefinitionIds.DRAFT);
        break;
      case this.programTableTypeIds.NonAccruingProgram:
        this.reviewForm.controls.StatusDefinitionId.setValue(this.statusDefinitionIds.DRAFT);
        break;
    }
    this.reviewForm.controls.ProgramType.setValue(this.ProgramCategoryGetNames[programType]);
    this.reviewForm.controls.ChangeException.setValue(this.isChangeException);
    this.reviewForm.controls.ChangeExceptionId.setValue(changeExceptionId);

    this.context.postdatatoservice(url, this.toFormData(this.reviewForm.value))
      .pipe(takeUntil(this.cancelSubject)).toPromise().then((response) => {
        if (response !== null) {
          if (response.id === this.statusDefinitionIds.DRAFT || response.id === this.statusDefinitionIds.ACCRUALS_COMPLETED) {
            this.openSuccessSnackBar('Program Canceled/Rerouted Successfully', 'x', 3000);
            if (this.isChangeException) {
              this.ChangeException();
            }
            else {
              this.router.navigate(['programs/edit-program/', + this.programForm.controls.ProgramCode.value]);
            }
          }
          else {
            this.openErrorSnackBar('Error in Cancelling/Rerouting the Program', 'x', 3000);
          }
        }
      },
        msg => { // Error;
          this.openErrorSnackBar('Error in Cancelling/Rerouting the Program', 'x', 3000);
        })
      .catch(error => this.openErrorSnackBar(error.message, 'x', 3000));
  }

  bindProductMangersAccrualsStatus() {
    if (this.programTableType == this.programTableTypeIds.Program) {
      let url = '/api/Program/Option/ProgramEmailNotification/' + this.progCode.toString() + '/' + moment(new Date(this.programForm.controls.BeginDate.value)).format('MM-DD-YYYY') + '/' + moment(new Date(this.programForm.controls.EndDate.value)).format('MM-DD-YYYY');
      this.context.getdatafromService(url).toPromise().then((response) => {
        if (response != null) {
          this.emailNotifications = response;
          this.dataSourceEmailNotifications = new MatTableDataSource(this.emailNotifications);
          this.dataSourceEmailNotifications.paginator = this.paginator;
          this.dataSourceEmailNotifications.sort = this.sort;
        }
      });
    }
  }

  bindAllFields() {
    this.context.getdatafromService('/api/Programs/GetProgram/id').toPromise().then((response) => {
      if (response != null) {
        this.programForm.patchValue({
          ProgramManager: response.programManager,

        })
      }
    });
  }

  truncateText(businessSegmentList: string) {
    return businessSegmentList.split(',').length > 1 ? businessSegmentList.split(',')[0] + '...' : businessSegmentList;
  }

  public closedailog() {
    this.dialog.closeAll();
  }
  getProductsAndAccrualsSummary() {
    this.routeParamsService.clearProgramEditNavigationSettings();
    this.routeParamsService.sendProgramEditNavigationRouteParams("Review");
    if (this.isffProductAccrualSummaryV2) {
      this.router.navigateByUrl('/accruals/accruals-products-summary-v2/' + this.progCode.toString());
    }
    else {
      this.router.navigateByUrl('/accruals/accruals-products-summary/' + this.progCode.toString());
    }
  }
  openSuccessSnackBar(message: string, action: string, duration?: number) {
    this.matSnackBar.open(message, action, { duration, panelClass: ['success-snackbar'] });
  }

  openErrorSnackBar(message: string, action: string, duration?: number) {
    this.matSnackBar.open(message, action, { duration, panelClass: ['error-snackbar'] });
  }

  navigateToProgram(programCode, status) {
    if (programCode.toString().includes('-') && (status == this.statusDefinitionIds.ACCRUALS_PENDING || status == this.statusDefinitionIds.ACCRUALS_COMPLETED || status == this.statusDefinitionIds.DRAFT)) {
      this.router.navigateByUrl('programs/edit-program/' + programCode);
    }
    else {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        if ((status == this.statusDefinitionIds.DRAFT || status == this.statusDefinitionIds.ACCRUALS_PENDING || status == this.statusDefinitionIds.ACCRUALS_COMPLETED) && this.canEditProgram()) {
          this.router.navigate(['programs/edit-program/', + programCode]);
        } else {
          this.router.navigateByUrl('programs/review-program/' + programCode);
        }
      });
    }
  }

  downloadFile(programCode, fileName) {
    if (fileName.includes('%') || fileName.includes('+')) {
      fileName = fileName.replace('%', '%25');
      fileName = fileName.replace('+', '');
    }
    this.context.getdatafromService(`/api/Program/Option/ProgramFiles/${programCode}/${fileName}`, 'blob').toPromise().then((response) => {
      if (response != null) {
        if (fileName.includes('%25')) {
          fileName = fileName.replace('%25', '%');
        }
        saveAs(response, fileName);
      }
    });
  }

  checkAttachmentFilesChange($event) {
    var filesUpload = this.programForm.controls.FileUpload.value;
    if (filesUpload && Array.isArray(filesUpload)) {
      if (filesUpload.length > 0) {
        //this.programSaveDraft = true;
      }
    }
  }

  onAttachmentSave() {
    if (this.programForm.controls.FileUpload.value) {
      this.checkAttachment();
    }
  }

  openWarningMessageDialog(): void {
    const dialogRef = this.dialog.open(DialogWarningMessageComponent, {
      disableClose: true,
      height: '340px',
      width: '550px',
      data: { dataMessage: this.warningMessages, dataContinueText: 'Save', dataShowErrorResolveMessage: true }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        if (result.dataConfirm == true) {
          this.saveAttachments();
        }
        this.warningMessages = [];
      }
    });
  }

  ReplicateProgram() {
    this.replicateProgram=true;
    if (this.replicateProgram == true) {
      const dialogRef = this.dialog.open(DialogWarningMessageComponent, {
        height: '340px',
        width: '550px',
        data: { progCode: this.progCode, dataContinueText: 'continue', }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result != undefined){
    }});
    }
  }

  checkAttachment() {
    var filesUpload = this.programForm.controls.FileUpload.value;
    var sizeOfUpload = 0;
    if (filesUpload && Array.isArray(filesUpload)) {
      for (const v of filesUpload) {
        this.fileDetailsList.forEach(key => {
          if (key.fileName == v.name) {
            this.warningMessages.push({ messageId: this.warningMessages.length + 1, message: this.attachmentErrors._1 + v.name, error: false });
          }
        })
        sizeOfUpload = sizeOfUpload + v.size; 
      }
      if (this.isffCheckAttachmentSize && sizeOfUpload > 0) {        
        if (sizeOfUpload > 30000000) {
          this.warningMessages.push({ messageId: this.warningMessages.length + 1, message: this.attachmentErrors._2, error: true });
        }
        if (this.warningMessages.length > 0) {
          this.openWarningMessageDialog();
          this.warningMessages = [];
        } else {
          this.saveAttachments();
        }
      } else {
        if (this.warningMessages.length > 0) {
          this.openWarningMessageDialog();
        } else {
          this.saveAttachments();
        }
      }
    }
  }

  saveAttachments() {
    this.context.postdatatoservice("/api/Program/Table/SaveAttachment", this.toProgramFormData(this.programForm.value))
      .pipe(takeUntil(this.cancelSubject)).toPromise().then((response) => {
        this.openSuccessSnackBar('Attachments Saved Successfully', 'x', 3000);
        window.location.reload();
      },
        msg => { // Error
          this.openErrorSnackBar('Error in Saving the Attachment', 'x', 3000);
        })
      .catch(error => this.openErrorSnackBar(error.message, 'x', 3000));
  }

  toProgramFormData<T>(formValue: T) {
    const formData = new FormData();
    const programCode = formValue['ProgramCode'];
    formData.append('ProgramCode', programCode);
    const fileUploadData = formValue['FileUpload'];
    if (fileUploadData) {
      if (fileUploadData && Array.isArray(fileUploadData)) { // check for arrays
        for (const v of fileUploadData) {
          formData.append('FileUpload', v);
        }
      }
    }
    return formData;
  }

  canEditProgram(): boolean {
    return this.core.canEditProgram;
  }

  canApproveOrDenyProgram(): boolean {
    return this.core.canApproveOrDenyProgram;
  }

  isAdmin(): boolean {
    return this.core.isAdmin;
  }

  onBack(): void {
    if (this.previousUrl.includes('review-program') && this.currentUrl.includes('review-program')) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([this.currentUrl]);
      });
    }
    this.location.back()
  }

  gotoParent() {
    const status = this.parentProgramStatus;
    const programCode = this.parentProgramCode;
    let url = "";
    if ((status == StatusDefinitionShortDesc._7 || status == StatusDefinitionShortDesc._1 || status == StatusDefinitionShortDesc._2) && this.canEditProgram()) {
      url = this.router.serializeUrl(
        this.router.createUrlTree(['programs/edit-program/' + programCode.toString()])
      );
    } else {
      url = this.router.serializeUrl(
        this.router.createUrlTree(['programs/review-program/' + programCode.toString()])
      );
    }
    window.open(url, '_blank');
  }

  editDoaApprovers() {
    this.openEditDoaApprovers();
  };

  openEditDoaApprovers(): void {
    const code = this.programForm.controls.CompanyCode.value == "CANADA" ? "CA" : "US";
    let totalExposure = 0;
    totalExposure = this.programForm.controls.TotalExposure.value == "" || this.programForm.controls.TotalExposure.value == null ? 0 : this.programForm.controls.TotalExposure.value;

    var appUser = this.appUserList.filter(au => au.userName === this.programForm.controls.ProgramManager.value && au.active);
    var ccAddress = appUser.length > 0 ? [appUser[0].email] : [this.core.getUsername()];
    if (appUser.length > 0) {
      if (this.core.isAdmin && (appUser[0].email != this.core.getUsername())) {
        ccAddress.push(this.core.getUsername());
      }
    }
    const dialogRef = this.dialog.open(EditProgramRouteforapprovalComponent, {
      disableClose: true,
      height: '700px',
      width: '1050px',
      data: { programId: this.programId, companyCode: code, programType: this.programTableType, totalExposure: totalExposure, programCode: this.programForm.controls.ProgramCode.value, programName: this.programForm.controls.ShortName, isChangeException: this.isChangeException, changeExceptionId: this.changeExceptionId, existingInActiveChangeExceptionRouting: this.existingInActiveChangeExceptionRouting, parentProgramName: this.parentProgramShortName, parentProgramCode: this.parentProgramCode, childBundleRoutingProgramList: this.childBundleRoutingProgramList, ccAddress: ccAddress,statusDefinitionId: this.statusDefinitionId }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigateByUrl('programs/review-program/' + this.progCode);
      });
    });

  }

  postMessage() {
    this.messagePosted = true;
    this.onSubmitMessage();
  }

  onSubmitMessage(): void {
    let url = '';
    url = '/api/Program/Table/InsertMessage';
    let changeExceptionId = 0;
    changeExceptionId = this.changeExceptionId === 0 ? this.changeExceptionId = null : this.changeExceptionId;
    if (this.childBundleRoutingProgramList.length > 0) {
      this.buildBundleRoutedMessageEmail(this.programForm.controls.ProgramCode.value)
    }
    else {
      this.buildMessageEmail(this.programForm.controls.ProgramCode.value)
    }
    let getMessagesUrl = '';
    if (!this.isChangeException) {
      getMessagesUrl = '/api/Program/Option/GetMessages/' + this.programId + '/' + this.programTableType;
    }
    else {
      getMessagesUrl = '/api/Program/ChangeException/Details/GetMessages/' + this.programId + '/' + this.programTableType + '/' + this.changeExceptionId
    }
    if (this.messagePosted == true) {
      const dialogRef = this.dialog.open(DialogPostMessageComponent, {
        height: '265px',
        width: '700px',
        data: { message: this.message }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result != undefined) {
          this.programMessage = { programId: this.programId, message: result.message, programType: this.programTableType, changeException: this.isChangeException, changeExceptionId: changeExceptionId, emailOptions: JSON.parse(JSON.stringify(this.messageEmailOptions)), statusDefinitionId: this.statusDefinitionId };
          const headers = new HttpHeaders().set('content-type', 'application/json');
          this.context.postdatatoservice(url, JSON.stringify(this.programMessage), headers)
            .toPromise().then((response) => {
              if (response != null) {
                this.context.getdatafromService(getMessagesUrl).toPromise().then((responseMsg) => {
                  if (responseMsg != null) {
                    this.messageList = responseMsg;
                    if (this.messageList.length > 0) {
                      this.showExpandButton = true;
                    }
                  }
                });
                this.openSuccessSnackBar('Message Sent', 'x', 3000);
              }
            },
              msg => { // Error
                this.openErrorSnackBar('Error in Posting the Message', 'x', 3000);
              });
        }
      });
    }
  }
  getUserName(userName) {
    var splitUserName = userName.split(',')
    this.userName = splitUserName.slice(1, 2) + " " + splitUserName.slice(0, 1)
  }
  comapreDate(routingStartDate, messageInserDate) {
    let result = false;
    if (routingStartDate > messageInserDate) {
      result = true;
    }
    return result;
  }
}

export class reviewprogramdropdowns {
  statusList: [];
  consistentNameList: [];
  companyCodeList: [];
  programChannelList: [];
  businessSegmentList: [];
  programTypeList: [];
  yearList: [];
}

export class ReviewProgramsDto {
  Code: string;
  ShortName: string;
  ConsistentName: string;
  Desc: string;
  TypeDefinition: string;
  StatusDefinition: string;
  BusinessSeg: string;
  Channel: string;
  CompanyCode: string;
  AppUserId: string;
  ProgramYear: string;
  TotalSales: any;
  TotalExposure: any;
  BeginDate: any;
  EndDate: any;
  Comment: string;
  ExpensePa: string;
  ProductMgrs: number[];
}

export interface EmailNotification {
  productManager: string;
  email: string;
  status: string;
  daysOldSinceEmailNotification: number;
  lastAccrualChangeDate: Date;
}

export interface ChangeExceptionProgramDetails {
  code: string;
  approvedDate?: any;
  statusDefinitionId: number;
}


export interface Section {
  itemName: string;
  updatedBy: string;
  updatedDate: Date;
}

export class Histories {
  histories: Section[] = [

  ];
}

export class Approvals {
  approvals: Section[] = [

  ];
}
