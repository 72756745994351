<div *ngIf="canViewProgram()">
    <mat-toolbar class="top-toolbar">
      <mat-toolbar-row>
        <div>
          <button mat-raised-button class="functional-button" (click)="onBack();"> 
               <mat-icon title="Back">keyboard_arrow_left</mat-icon> Back
             </button>
         </div>
        <span class="header-page">{{!isChangeException || !isExistingChangeException ?'Admin Edit Program':'Admin Edit Change Exception'}}</span>
        <span class="header-page-spacer"></span>
        <div *ngIf="canEditProgram()">
          <a [routerLink]="'../../search-programs/'"><button mat-raised-button class="functional-button">Cancel</button></a>
          <button mat-raised-button class="functional-button" (click)="verifyDelete();">{{'Delete'}}</button>       
          <button mat-raised-button class="functional-button" [disabled]="!programForm.valid" (click)="validateTotalExposure(false,isChangeException);">{{'Save'}}</button>       
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
    <div class="horizontal-scroll-wrapper">
      <table>
        <tr>        
          <td class="td-column">
            <div class="main-cards">
              <form [formGroup]="programForm">  
                <mat-card class="card">
                  <span class="span">
                    <mat-icon class="matcard-icon" title="Program Details">assignment</mat-icon>
                    <mat-card-header>{{!isChangeException || !isExistingChangeException ?'Program Details':'Change Exception Details'}}</mat-card-header>
                  </span>
                  <mat-card-content>
                    <div class="row">
                      <mat-form-field appearance="fill" class="col-md-6" *ngIf="!isChangeException">
                        <mat-label>Program Name</mat-label>
                        <input matInput maxlength="50"
                          [ngClass]="{ 'is-invalid': (submitted && programForm.controls.ShortName.errors != null) || programForm.controls.ShortName.errors != null}"
                          formControlName="ShortName"(keyup)="formControlChange();" required>
                        <mat-hint>Max 50 characters!</mat-hint>
                        <mat-error
                          *ngIf="f.ShortName.touched && f.ShortName.errors && programForm.controls.ShortName.value?.length ==0">
                          Program Name is required!</mat-error>
                        <mat-error *ngIf="programForm.controls.ShortName.errors != null && programForm.controls.ShortName.value?.length !=0">Program Name already
                          exists!</mat-error>
                      </mat-form-field>
                      <mat-form-field class="col-md-6 readonly" *ngIf="isChangeException">
                        <mat-label>Program Name</mat-label>
                        <input matInput
                          [ngClass]="{ 'is-invalid': (submitted && programForm.controls.ShortName.errors != null) || programForm.controls.ShortName.errors != null}"
                          formControlName="ShortName" readonly>
                      </mat-form-field>
                      <mat-form-field class="col-md-3 readonly">
                        <mat-label>{{!isChangeException || !isExistingChangeException?'Program Code':'Change Exception Code'}}</mat-label>
                        <input matInput formControlName="ProgramCode" readonly>
                      </mat-form-field>
                      <mat-form-field class="col-md-3 readonly">
                        <mat-label>Program Manager</mat-label>
                        <input matInput formControlName="ProgramManager" readonly>
                      </mat-form-field>
                    </div>
                    <br>
                    <div class="row">
                      <mat-form-field appearance="fill" class="col-md-6" *ngIf="!isChangeException">
                        <mat-label>Consistent Program Name</mat-label>
                        <input type="text" placeholder="Search Program Name" aria-label="Number" matInput
                          formControlName="ConsistentName" [matAutocomplete]="consistentName" (keyup)="formControlChange();">
                        <mat-autocomplete autoActiveFirstOption #consistentName="matAutocomplete" (optionSelected)="formControlChange();" >
                          <mat-option *ngFor="let name of filteredconsistentNameList | async" [value]="name">
                            {{name}}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>                      
                      <mat-form-field class="col-md-6 readonly" *ngIf="isChangeException">
                        <mat-label>Consistent Program Name</mat-label>
                        <input type="text" placeholder="Search Program Name" aria-label="Number" matInput
                          formControlName="ConsistentName" readonly>
                      </mat-form-field>                                          
                      <mat-form-field appearance="fill" class="col-md-6">
                        <mat-label>{{!isChangeException || !isExistingChangeException?'Program Status':'Change Exception Status'}}</mat-label>
                        <mat-select [ngClass]="{ 'is-invalid': submitted || !submitted }" formControlName="ProgramStatus" (selectionChange)="formControlChange();">
                          <mat-option *ngFor="let status of availableStatusDefinitions" [value]="status.code">
                            {{status.code}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <br>
                    <div class="row" *ngIf="!isChangeException">
                      <mat-form-field appearance="fill" class="col-md-12">
                        <mat-label>Program Description</mat-label>
                        <input matInput
                          [ngClass]="{ 'is-invalid': (submitted && programForm.controls.ProgramDesc.errors != null) || programForm.controls.ProgramDesc.errors != null}"
                          formControlName="ProgramDesc"(keyup)="formControlChange();" required>
                        <mat-error
                          *ngIf="f.ProgramDesc.touched && f.ProgramDesc.errors && programForm.controls.ProgramDesc.value?.length ==0">
                          Program Description is required!</mat-error>
                      </mat-form-field>
                    </div>                   
                    <div class="row" *ngIf="isChangeException">
                      <mat-form-field appearance="fill" class="col-md-12">
                        <mat-label>{{isNewExplanation ?'New Change Exception Explanation':'Change Exception Explanation'}}</mat-label>
                        <textarea matInput rows="10" (keyup)="formControlChange();"
                          formControlName="Explanation"></textarea>
                      </mat-form-field>
                      <mat-error
                          *ngIf="f.Explanation.touched && f.Explanation.errors && programForm.controls.Explanation.value?.length ==0">
                          Change Exception Explanation is required!</mat-error>
                    </div>                    
                  </mat-card-content>
                </mat-card>
                <mat-card class="card">
                  <span class="span">
                    <mat-icon class="matcard-icon" title="Program Attributes">apartment</mat-icon>
                    <mat-card-header>Program Attributes </mat-card-header>
                  </span>
                  <mat-card-content>
                    <div class="row">
                      <mat-form-field class="col-md-6 readonly">
                        <mat-label>Company Name</mat-label>
                        <input matInput formControlName="CompanyCode" readonly>
                      </mat-form-field>
                      <mat-form-field class="col-md-6 readonly">
                        <mat-label>Program Channel</mat-label>
                        <input matInput formControlName="Channel" readonly>
                      </mat-form-field>
                    </div> <br>
                    <div class="row">
                      <mat-form-field class="col-md-6 readonly">
                        <mat-label>Business Segment</mat-label>
                        <input matInput formControlName="BusinessSegments" readonly>
                      </mat-form-field>
                      <mat-form-field class="col-md-6 readonly">
                        <mat-label>Program Type</mat-label>
                        <input matInput formControlName="TypeDefinition" readonly>
                      </mat-form-field>
                    </div>
                  </mat-card-content>
                </mat-card>
                <mat-card class="card">
                  <span class="span">
                    <mat-icon class="matcard-icon" title="Program Dates">date_range</mat-icon>
                    <mat-card-header>{{!isChangeException|| !isExistingChangeException ?'Program Dates':'Change Exception Dates'}}</mat-card-header>
                  </span>
                  <mat-card-content>
                    <div class="row">                     
                      <mat-form-field color="#1A81E1" appearance="fill" class="col-md-4">
                        <mat-label>Start Date</mat-label>
                        <input [matDatepickerFilter]="onlyFirstDay" readonly
                          (dateChange)="startDateChange();" matInput [matDatepicker]="picker1"
                          [ngClass]="{ 'is-invalid': (submitted && f.BeginDate.errors) || f.BeginDate.errors }"
                          formControlName="BeginDate" required>
                        <mat-error *ngIf="f.BeginDate.errors?.required">Start Date is required!</mat-error>
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1 color="#1A81E1"></mat-datepicker>
                        <mat-error *ngIf="f.BeginDate.errors?.matDatepickerMin || f.BeginDate.errors?.matDatepickerMax">
                            Start Date is Invalid!</mat-error>
                      </mat-form-field>                    
                      <mat-form-field color="#1A81E1" appearance="fill" class="col-md-4">
                        <mat-label>End Date</mat-label>
                        <input matInput [matDatepickerFilter]="onlyLastDay" readonly
                          (dateChange)="endDateChange()" [matDatepicker]="picker2"
                          [ngClass]="{ 'is-invalid': (submitted && f.EndDate.errors) || f.EndDate.errors }"
                          formControlName="EndDate" required>
                        <mat-error *ngIf="f.EndDate.errors?.required">End Date is required!</mat-error>
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2 color="#1A81E1"></mat-datepicker>
                        <mat-error *ngIf="f.EndDate.errors?.matDatepickerMin || f.EndDate.errors?.matDatepickerMax">End
                            Date is Invalid!</mat-error>
                      </mat-form-field>                                                                   
                      <mat-form-field appearance="fill" class="col-md-4">
                        <mat-label>Program Year</mat-label>
                        <mat-select [ngClass]="{ 'is-invalid': submitted && f.ProgramYear.errors,'changeExceptionProgramYear':(isChangeException || statusDefinitionId === statusDefinitionIds.APPROVED)}"
                          formControlName="ProgramYear" required (selectionChange)="formControlChange();">
                          <mat-option *ngFor="let yearValue of yearList" [value]="yearValue.id">
                            {{yearValue.shortDesc}}
                          </mat-option>
                        </mat-select>
                          <mat-error *ngIf="submitted && f.ProgramYear.errors && f.ProgramYear.errors.required">Program Year is required!</mat-error>
                        </mat-form-field>                        
                    </div>
                  </mat-card-content>
                </mat-card>
                <!-- new implemented Financial card start -->
                <mat-card class="card">
                  <span class="span">
                    <mat-icon class="matcard-icon" title="Financial Assumptions">account_balance</mat-icon>
                    <mat-card-header *ngIf="!showParentProgram">{{!isChangeException || !isExistingChangeException ?'Financial
                      Assumptions':'Change Exception Financial Assumptions'}}</mat-card-header>
                    <mat-card-header *ngIf="showParentProgram">Parent Program Financial Assumptions </mat-card-header>
                  </span>
                  <div class="row top-row col-md-12">
                    <div class="productsAndAccrualsLink" [hidden]="!(isStandardOrParentTypeProgram && !showParentProgram) || programTableType !== programTableTypeIds.Program || emailNotifications.length === 0">
                      <a [routerLink]="[]" (click)="getProductsAndAccrualsSummary()">Products and
                        Accruals Summary </a>
                      </div>
                  </div>
                  <div class="col-md-12 financialcard-view">
                    <mat-card-content>                           
                      <div class="row first-row"
                        [ngClass]="{'green-border-color': statusDefinitionId === statusDefinitionIds.APPROVED,'orange-border-color': statusDefinitionId === statusDefinitionIds.ACCRUALS_PENDING || statusDefinitionId === statusDefinitionIds.ACCRUALS_COMPLETED || statusDefinitionId === statusDefinitionIds.APPROVAL_ROUTING || statusDefinitionId === statusDefinitionIds.DRAFT,'red-border-color': statusDefinitionId === statusDefinitionIds.CANCELED || statusDefinitionId === statusDefinitionIds.DENIED}">
                        <div class="col-md-4" *ngIf="!showParentProgram">                          
                          <mat-form-field  class="col-md-12  padding-0" appearance="fill">
                              <mat-label>Total Sales (Local Currency)</mat-label>
                              <input (keypress)="restrictChar($event);" maxlength="13" type="text" (keyup)="formControlChange();"
                                [cleave]="{numeral: true, numeralThousandsGroupStyle: 'thousand'}" matInput formControlName="TotalSales"
                                [ngClass]="{ 'is-invalid': submitted && f.TotalSales.errors }">
                              <span matPrefix>$&nbsp;</span>
                                <mat-error *ngIf="submitted && f.TotalSales.errors && f.TotalSales.errors.required">Total Sales is required!</mat-error>
                            </mat-form-field>                          
                        </div>
                        <div class="col-md-4" *ngIf="showParentProgram">                          
                          <mat-form-field  class="col-md-12  readonly">
                              <mat-label>Total Sales (Local Currency)</mat-label>
                              <input matInput formControlName="TotalSales" [cleave]="{numeral: true, numeralThousandsGroupStyle: 'thousand'}" readonly>
                              <span matPrefix>$&nbsp;</span>
                          </mat-form-field>                          
                        </div>
                        <div class="col-md-4" *ngIf="!showParentProgram">                  
                          <mat-form-field class="col-md-12  padding-0" appearance="fill">
                            <mat-label> Total Exposure (Local Currency)</mat-label>
                            <input (keypress)="restrictChar($event);" maxlength="11" type="text"
                              [cleave]="{numeral: true, numeralThousandsGroupStyle: 'thousand'}" matInput formControlName="TotalExposure"
                              [ngClass]="{ 'is-invalid': submitted && f.TotalExposure.errors }" (keyup)="formControlChange();" >
                            <span matPrefix>$&nbsp;</span>
                              <mat-error *ngIf="submitted && f.TotalExposure.errors && f.TotalExposure.errors.required">Total Exposure is required!</mat-error>
                          </mat-form-field>                        
                        </div>
                        <div class="col-md-4" *ngIf="showParentProgram">                          
                          <mat-form-field  class="col-md-12 readonly">
                              <mat-label>Total Exposure (Local Currency)</mat-label>
                              <input matInput formControlName="TotalExposure" [cleave]="{numeral: true, numeralThousandsGroupStyle: 'thousand'}" readonly>
                              <span matPrefix>$&nbsp;</span>
                          </mat-form-field>                          
                        </div>
                      </div>
                      <div class="row child-row"*ngFor="let item of totalSalesAndTotalExposureList; let i=index;"
                        [ngClass]="{'green-border-color': item.status === statusDefinitionIds.APPROVED,'orange-border-color': item.status === statusDefinitionIds.ACCRUALS_PENDING || item.status === statusDefinitionIds.ACCRUALS_COMPLETED || item.status === statusDefinitionIds.APPROVAL_ROUTING || item.status === statusDefinitionIds.DRAFT,'red-border-color': item.status === statusDefinitionIds.CANCELED || item.status === statusDefinitionIds.DENIED}">
                        <div class="col-md-4">
                          <div class="row">
                            <mat-label class="child-labels ">Total Sales (Local Currency)</mat-label>
                          </div>
                          <div class="row">
                            <mat-label class="childcurrency">{{item.totalSales | currency: 'USD':'symbol':'1.0-0'}}</mat-label>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="row">
                            <mat-label class="child-labels exposurealignment">Total Exposure (Local Currency)</mat-label>
                          </div>
                          <div class="row">
                            <mat-label  class="childcurrency lblexposure">{{item.totalExposure | currency: 'USD':'symbol':'1.0-0'}}</mat-label>
                          </div>
                
                        </div>                      
                        <div class="col-md-5">
                          <mat-label>{{item.programType}}-</mat-label>
                          <mat-label>{{item.programCode}}</mat-label>
                        </div>
                      </div>
                    </mat-card-content>
                  </div>
                  <mat-card-content>
                    <div [hidden]="!(isStandardOrParentTypeProgram && !showParentProgram) || programTableType !== programTableTypeIds.Program || emailNotifications.length === 0">
                      <div class="dash" *ngIf="emailNotifications.length > 0">
                      </div>
                      <br>
                      <div class="matgrid-div" [hidden]="emailNotifications.length === 0">
                        <div class="table-container">
                          <table mat-table class="mat-grid" [dataSource]="dataSourceEmailNotifications" #sort="matSort"
                            matSort>
  
                            <ng-container matColumnDef="productManager">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> PRODUCT MANAGER </th>
                              <td mat-cell *matCellDef="let element"> {{ element.productManager }} </td>
                            </ng-container>
  
                            <ng-container matColumnDef="email">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header>EMAIL</th>
                              <td mat-cell *matCellDef="let element"><a href='mailto: {{ element.email }}'> {{
                                  element.email }} </a> </td>
                            </ng-container>
  
                            <ng-container matColumnDef="status">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> STATUS </th>
                              <td mat-cell *matCellDef="let element"> {{ element.status }} </td>
                            </ng-container>
  
                            <ng-container matColumnDef="daysOldSinceEmailNotification">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> DAYS OLD SINCE EMAIL NOTIFICATION
                              </th>
                              <td mat-cell class="mat-cell-align-center" *matCellDef="let element"> {{
                                element.daysOldSinceEmailNotification }} </td>
                            </ng-container>

                            <ng-container matColumnDef="lastAccrualChangeDate">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> DATE OF LAST ACCRUAL CHANGE
                              </th>
                              <td mat-cell class="mat-cell-align-center" *matCellDef="let element"> {{
                                element.lastAccrualChangeDate | date: 'M/d/yyyy'}} </td>
                            </ng-container>
  
                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
                            </tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  
                          </table>
                        </div>
                        <div>
                          <mat-paginator #paginator [pageSizeOptions]="[5, 10]" showFirstLastButtons
                            [hidden]="emailNotifications.length === 0"></mat-paginator>
                        </div>
                      </div>
                    </div>
                  </mat-card-content>
                </mat-card>
              </form>  
            </div>
          </td>
        </tr>
      </table>
    </div>
    <ngx-spinner class="spinner" size="default" type="ball-spin-clockwise">
      <p>Please Wait.</p>
    </ngx-spinner>
  </div>
  